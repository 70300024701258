<template>
  <div class="manage-branch-edit animatedBox" v-if="loaded">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm" @error="validateError">
        <div class="card p-2 mb-2">
          <h4 class="mainTitle pt-3 pb-4">
            Edit Branch #{{ this.$route.params.id }}
          </h4>
          <!-- Branch Details -->
          <fd-form-section title="Branch Details" class="px-2">
            <div class="col-12 row mb-2">
              <fd-input
                v-model="branchData.name"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Branch Name"
                name="name"
                type="text"
                :validators="[
                  validators.required,
                  (val) => validators.min(val, 3)
                ]"
              >
              </fd-input>
              <fd-input
                v-model="branchData.branchCode"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Branch Code"
                name="branchCode"
                type="text"
                :validators="[validators.required]"
              >
              </fd-input>
            </div>

            <fd-input
              v-model="branchData.registerEstateAgentName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Register Estate Agent Name"
              name="registerEstateAgentName"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <fd-input
              v-model="branchData.businessRegisterNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Board Reg. No."
              name="businessRegisterNumber"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <fd-input
              v-model="branchData.ssmNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="SSM"
              name="ssmNumber"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <fd-input
              v-model="branchData.sstNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="SST No."
              name="sstNumber"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <fd-input
              v-model="branchData.ejenHartanahNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Ejen Hartanah No."
              name="ejenHartanahNumber"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>

            <fd-textarea
              v-model="branchData.description"
              class="col-12 px-1 mb-2"
              label="Description"
              name="description"
              type="text"
            >
            </fd-textarea>
          </fd-form-section>
          <!-- Contact Details -->
          <fd-form-section title="Contact Details" class="px-2">
            <fd-input
              v-model="branchData.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="email"
              type="text"
              :validators="[validators.required, validators.email]"
            >
            </fd-input>
            <fd-input
              v-model="branchData.contact"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Contact"
              name="contact"
              type="text"
              :validators="[validators.required, validators.phone]"
            >
            </fd-input>
            <fd-input
              v-model="branchData.fax"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Fax"
              name="fax"
              type="text"
              :validators="[validators.fax]"
            >
            </fd-input>
            <fd-input
              v-model="branchData.tenancyAgreementPersonInChargeEmail"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Tenancy Agreement Person In Charge Email"
              name="tenancyAgreementPersonInChargeEmail"
              type="email"
              :validators="[validators.required, validators.email]"
            >
            </fd-input>
          </fd-form-section>
          <!-- Address Details -->
          <fd-form-section title="Address Details" class="px-2">
            <fd-input
              v-model="branchData.address"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Address"
              name="address"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <addresses
              v-if="branchData.country"
              isEdit
              :country.sync="branchData.country"
              :state.sync="branchData.state"
              :city.sync="branchData.city"
              :area.sync="branchData.area"
              class="d-flex row col-12"
              @change="
                (data) => {
                  branchData.countryId = data.country.id;
                  branchData.stateId = data.state.id;
                  branchData.cityId = data.city.id;
                  branchData.areaId = data.area.id;
                }
              "
            ></addresses>
            <!-- Longitude & Latitude (Google Maps) -->
            <!-- <div class="my-2 w-100">
              <map-component
                @mapUpdate="updateMap"
                :initialGeometry="{
                  lat: branchData.latitude,
                  lng: branchData.longitude
                }"
              ></map-component>
            </div> -->
          </fd-form-section>
          <div class="text-right p-2">
            <button class="btn main">Update</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  phone,
  fax
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import Addresses from "@/components/GlobalComponents/Address";

import AgencyBranchModel from "@/models/agencyBranch";

export default {
  name: "manage-branch-edit",
  components: {
    Addresses
    // "map-component": ()=> import('@/components/GlobalComponents/GoogleMap/GoogleMap')
  },
  data() {
    return {
      loaded: false,

      // Branch
      branchData: {},

      validators: {
        required: required,
        min: minLength,
        email: email,
        phone: phone,
        fax: fax
      }
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.$store.commit("setIsLoading", true);
      await this.getBranchData();
      this.$store.commit("setIsLoading", false);
    },
    // ================================= Misc ==================================
    mapArrDataIntoObjWithId(array) {
      let formData = [];
      array.forEach((val) => {
        formData.push({ id: val });
      });
      return formData;
    },

    // ============================== API Related ==============================
    async getBranchData() {
      try {
        let data = await this.$store.dispatch("manageBranch/getBranch", {
          id: this.$route.params.id
        });
        this.branchData = AgencyBranchModel.getToEditResponse(data);
        this.loaded = true;
      } catch (error) {
        throw error;
      }
    },
    updateMap(val) {
      this.branchData = {
        ...this.branchData,
        latitude: val.geometry.lat,
        longitude: val.geometry.lng
      };
    },
    validateError() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Validation failed, please check if all the inputs are valid."
      });
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        await this.$store.dispatch("manageBranch/updateBranch", {
          id: this.$route.params.id,
          payload: AgencyBranchModel.postPayload(this.branchData)
        });

        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Branch is updated successfully."
        });
        this.$router.push({ name: "ManageBranch" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-branch-edit {
  form {
    @extend %formDesign;
  }
}
</style>
